const homeBack = {
  video: "https://player.vimeo.com/progressive_redirect/playback/713523699/rendition/720p/file.mp4?loc=external&signature=b910ba34bc70d195f66ccc26f3774b0937244f781af650be3edbf2fcdcb2397d",
  image: "./videos/posters/home.jpg",
};
const defaultBack = {
  video: "https://player.vimeo.com/progressive_redirect/playback/713523934/rendition/720p/file.mp4?loc=external&signature=13c6df6b0726b2ab3444e50999e9db840bdb34325484b716637a74fb273d5524",
  image: "./videos/posters/back4.jpg",
};

module.exports = {
  homeBack,
  defaultBack,
};
