<template>
  <video-bg :sources="sources" :img="$store.state.publicPath + settings.img" playsinline :style="styleObject"></video-bg>
</template>

<script>
import VideoBg from "vue-videobg";
export default {
  mounted() {
    let video = document.querySelector(".VideoBg video");
    video.setAttribute("playsinline", "playsinline");
    const sources = video.querySelectorAll("source");
    [...sources].forEach((source) => {
      source.setAttribute("type", "video/mp4");
    });
  },
  props: ["settings"],
  data() {
    return {
      styleObject: {
        opacity: this.settings.opacity || 1,
      },
    };
  },
  components: {
    "video-bg": VideoBg,
  },
  computed: {
    sources() {
      let newArr = [];
      this.settings.sources.forEach((item) => {
        if (item.includes("vimeo")) {
          newArr.push(item);
        } else {
          newArr.push(this.$store.state.publicPath + item);
        }
      });
      return newArr;
    },
  },
};
</script>

<style lang="scss">
.VideoBg {
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}
</style>
