<template>
  <v-container class="pa-0 mt-5" fluid style="height: 100%;">
    <background-video :settings="backgroundVideo"></background-video>
    <div class="to-front">
      <div class="d-flex flex-row justify-start align-center">
        <page-headline :text="headline"></page-headline>
      </div>

      <div class="ma-5">
        <div
          v-if="!$store.state.news.content.length"
          class="d-flex justify-center align-center"
          style="height: calc(70vh)"
        >
          <v-card elevation="2" class="mx-auto mt-10" max-width="500">
            <v-card-text class="pb-1">
              <p class="text-h4 text--primary text-center">
                {{ message }}
              </p>
            </v-card-text>
          </v-card>
        </div>

        <v-row v-if="$store.state.news.content.length">
          <v-col
            v-for="article in news.content"
            :key="article.id"
            class="d-flex child-flex pa-6"
            cols="12"
            sm="6"
            md="4"
            xl="4"
          >
            <v-card
              class="mx-auto"
              href=""
              target="_blank"
              @click="openLink(article)"
            >
              <v-img
                class="white--text align-end"
                cover
                aspect-ratio="1.5"
                :src="article.image"
              >
              </v-img>

              <v-card-text class="text--primary">
                <div class="news-title card-headline mb-3">
                  {{ article.title }}
                </div>
                <div class="news-author news-card-text">
                  By: {{ article.author }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
import { defaultBack } from "../helpers/video-background-paths";
export default {
  mounted() {
    this.$store.dispatch("getNews");
  },
  data() {
    return {
      headline: "IN THE NEWS",
      backgroundVideo: {
        sources: [defaultBack.video],
        img: defaultBack.image,
        opacity: 1,
      },
    };
  },
  methods: {
    openLink(article) {
      window.open(article.link);
    },
  },
  components: {
    "background-video": require("@/components/shared/BackgroundVideo.vue")
      .default,
    "page-headline": require("@/components/shared/PageHeadline.vue").default,
  },
  computed: {
    news: {
      get() {
        console.log("news: ", this.$store.getters.news);
        return this.$store.getters.news;
      },
    },
    message: {
      get() {
        if (this.$store.getters.news.status === null) return "Loading...";
        return "Sorry, No News Available.";
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.news-title {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  color: #000;
  font-size: 1.25rem;
}

.news-author {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #000;
  font-size: 1rem;
}
</style>
