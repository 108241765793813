<template>
  <div class="page-headline-container d-flex">
    <div class="page-headline py-6 pr-10 pl-10">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["text"],
};
</script>

<style lang="scss">
.page-headline-container {
  position: relative;
}
.page-headline {
  white-space: nowrap;
  color: #fff;
  background: rgba(0, 0, 0, 0.95) !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  font-size: 2rem;
}
</style>
